<template>
  <v-app class="app bg-color">
    <v-main>
      <v-container class="fill-height">
        <v-card class="mx-auto text-center" dark color="rgba(0,0,0,0)">
          <div class="text-error">403!</div>
          <div class="text-h6">
            You do not have permission to view this page
          </div>
          <v-btn
            color="primary"
            depressed
            :to="$i18n.getRouteLink('dashboard')"
            class="mt-5"
          >
            <span class="third--text">BACK TO HOME</span>
          </v-btn>
        </v-card>
      </v-container>
    </v-main>
    <footer-app></footer-app>
  </v-app>
</template>

<script>
import FooterApp from './../../components/commons/footer';
export default {
  data: () => ({}),
  components: {
    FooterApp
  }
}
</script>